<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="https://cdn.altibbi.com/global/altibbi-imgs/altibbi_logo.png"
            max-height="40"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
          :class="{'hidden': item.hidden}"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/',
          userTypes: ['agent', 'altibbi', 'admin'],
          hidden: false
        },
        {
          title: 'durations',
          icon: 'mdi-alarm',
          to: '/durations',
          userTypes: ['agent', 'altibbi', 'admin'],
          hidden: false
        },
        {
          title: 'branches',
          icon: 'mdi-domain',
          to: '/branches',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'coupons',
          icon: 'mdi-qrcode',
          to: '/coupons',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'subscribers',
          icon: 'mdi-account-multiple-check',
          to: '/subscribers',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },

        {
          title: 'sheets',
          icon: 'mdi-google-spreadsheet',
          to: '/sheets',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'users',
          icon: 'mdi-account-group',
          to: '/users',
          hidden: false,
          userTypes: ['super pharmacist', 'altibbi', 'admin']
        },
        {
          title: 'subscription_reports',
          icon: 'mdi-file-chart',
          to: '/reports/subscriptions',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'optional_fields',
          icon: 'mdi-cog',
          to: '/optional-fields',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'consultations',
          icon: 'mdi-help-circle',
          to: '/consultations',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'consultation_referrals',
          icon: 'mdi-arrange-bring-forward',
          to: '/consultation-referrals',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'consultation_reports',
          icon: 'mdi-file-chart',
          to: '/reports/consultations',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'notifications',
          icon: 'mdi-bell',
          to: '/notifications',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'sms',
          icon: 'mdi-message',
          to: '/sms',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'dispense',
          icon: 'mdi-truck-delivery',
          to: '/consultation-drug-delivery',
          hidden: false,
          userTypes: ['agent', 'altibbi', 'admin']
        },
        {
          title: 'prescription',
          icon: 'mdi-truck-delivery',
          to: { name: 'prescriptions.list' },
          hidden: false,
          userTypes: ['pharmacist', 'super pharmacist', 'altibbi', 'admin']
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$auth.getPartnerName().charAt(0).toUpperCase() + this.$auth.getPartnerName().slice(1) + ' Partner'
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
          hidden: this.isHidden(item)
        }
      },
      isHidden (item) {
        if (!this.$auth.getEnabledFeatures().includes(item.title)) {
          return true
        }

        if (!('userTypes' in item)) {
          return false
        }

        return item.userTypes.every(itemRole => this.$auth.getUserType() !== itemRole)
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  .hidden
    display: none

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
